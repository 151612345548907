import { useMediaQuery } from "@mui/material";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

export const ThemeModeContext = createContext<"light" | "dark">("light");
export const ToggleThemeModeContext = createContext<
  (mode: "light" | "dark") => void
>((_mode) => {});

export default function ThemeModeProvider({ children }: PropsWithChildren) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const savedPreference =
    typeof window !== "undefined"
      ? localStorage.getItem("themePreference")
      : null;
  const userPreference =
    savedPreference !== null ? JSON.parse(savedPreference) : null;

  const initialMode =
    userPreference !== null
      ? userPreference
      : prefersDarkMode
      ? "dark"
      : "light";

  const [mode, setMode] = useState<"light" | "dark">(initialMode);

  useEffect(() => {
    localStorage.setItem("themePreference", JSON.stringify(mode));
  }, [mode]);

  return (
    <ThemeModeContext.Provider value={mode}>
      <ToggleThemeModeContext.Provider value={setMode}>
        {children}
      </ToggleThemeModeContext.Provider>
    </ThemeModeContext.Provider>
  );
}

export function useThemeMode() {
  return useContext(ThemeModeContext);
}

export function useToggleThemeMode() {
  return useContext(ToggleThemeModeContext);
}

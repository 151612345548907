import { alpha, Grow, Slide, ThemeOptions } from "@mui/material";
import chroma from "chroma-js";

declare module "@mui/material/styles" {
  interface Palette {
    charts: Array<string>;
    complementary: {
      positives: Array<string>;
      negatives: Array<string>;
      neutrals: Array<string>;
    };
  }
  interface PaletteOptions {
    charts: Array<string>;
    complementary: {
      positives: Array<string>;
      negatives: Array<string>;
      neutrals: Array<string>;
    };
  }
  interface TypeText {
    primaryWhite: string;
    secondaryWhite: string;
    primaryBlack: string;
    secondaryBlack: string;
  }
}

const baseTheme: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-Bold.eot');
    src: local('Mabry Pro Bold'), local('MabryPro-Bold'),
        url('/font/MabryPro/MabryPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-Bold.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-Bold.woff') format('woff'),
        url('/font/MabryPro/MabryPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-Medium.eot');
    src: local('Mabry Pro Medium'), local('MabryPro-Medium'),
        url('/font/MabryPro/MabryPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-Medium.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-Medium.woff') format('woff'),
        url('/font/MabryPro/MabryPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-Black.eot');
    src: local('Mabry Pro Black'), local('MabryPro-Black'),
        url('/font/MabryPro/MabryPro-Black.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-Black.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-Black.woff') format('woff'),
        url('/font/MabryPro/MabryPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-LightItalic.eot');
    src: local('Mabry Pro Light Italic'), local('MabryPro-LightItalic'),
        url('/font/MabryPro/MabryPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-LightItalic.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-LightItalic.woff') format('woff'),
        url('/font/MabryPro/MabryPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-BoldItalic.eot');
    src: local('Mabry Pro Bold Italic'), local('MabryPro-BoldItalic'),
        url('/font/MabryPro/MabryPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-BoldItalic.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-BoldItalic.woff') format('woff'),
        url('/font/MabryPro/MabryPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-Light.eot');
    src: local('Mabry Pro Light'), local('MabryPro-Light'),
        url('/font/MabryPro/MabryPro-Light.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-Light.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-Light.woff') format('woff'),
        url('/font/MabryPro/MabryPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-BlackItalic.eot');
    src: local('Mabry Pro Black Italic'), local('MabryPro-BlackItalic'),
        url('/font/MabryPro/MabryPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-BlackItalic.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-BlackItalic.woff') format('woff'),
        url('/font/MabryPro/MabryPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-MediumItalic.eot');
    src: local('Mabry Pro Medium Italic'), local('MabryPro-MediumItalic'),
        url('/font/MabryPro/MabryPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-MediumItalic.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-MediumItalic.woff') format('woff'),
        url('/font/MabryPro/MabryPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-Italic.eot');
    src: local('Mabry Pro Italic'), local('MabryPro-Italic'),
        url('/font/MabryPro/MabryPro-Italic.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-Italic.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-Italic.woff') format('woff'),
        url('/font/MabryPro/MabryPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mabry Pro';
    src: url('/font/MabryPro/MabryPro-Regular.eot');
    src: local('Mabry Pro'), local('MabryPro-Regular'),
        url('/font/MabryPro/MabryPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('/font/MabryPro/MabryPro-Regular.woff2') format('woff2'),
        url('/font/MabryPro/MabryPro-Regular.woff') format('woff'),
        url('/font/MabryPro/MabryPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


      `,
    },
    MuiButton: {
      defaultProps: { disableElevation: true },
      styleOverrides: {
        text: { textTransform: "none" },
        contained: { boxShadow: "none" },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: { textTransform: "none", boxShadow: "none" },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: { borderRadius: 1000, backgroundColor: alpha("#1B160E", 0.12) },
        groupedHorizontal: { borderRadius: "1000px !important" },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
          fontWeight: 600,
          borderRadius: 1000,
          borderWidth: 0,
          "&.Mui-selected": {
            color: "#ffffff",
            fontWeight: 700,
            borderRadius: 1000,
            "&.MuiToggleButton-primary": {
              backgroundColor: "#4846D2",
            },
            "&.MuiToggleButton-secondary": {
              backgroundColor: "#1B160E",
            },
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "elevation",
        elevation: 0,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: { alignSelf: "baseline" },
        label: { fontWeight: 500 },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          ...(ownerState.variant === "filled" && {
            color: ownerState.severity
              ? theme.palette[ownerState.severity].contrastText
              : "#fff",
          }),
        }),
      },
    },
    MuiSnackbar: {
      defaultProps: {
        TransitionComponent: (props) => <Slide direction="up" {...props} />,
      },
    },
    MuiTooltip: {
      defaultProps: {
        TransitionComponent: Grow,
      },
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: theme.palette.primary.dark,
          color: theme.palette.primary.contrastText,
          fontWeight: 600,
          border: "solid 1px",
          borderColor: theme.palette.divider,
        }),
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: "Mabry Pro",
    button: {
      textTransform: "none",
      fontWeight: 600,
    },
    h1: { fontWeight: 600 },
    h2: { fontWeight: 600 },
  },
};

const primaryColor = chroma("#4846D2");
const secondaryColor = chroma("#1B160E");
const successColor = chroma("#6CA35C");
const errorColor = chroma("#C63737");
const warningColor = chroma("#F3A341");
const infoColor = chroma("#3896DE");

// Tema Claro
const lightPrimary = primaryColor.hex();
const lightSecondary = secondaryColor.set("hsl.l", 0.1).hex();
const lightSuccess = successColor.set("hsl.l", 0.45).set("hsl.s", 0.5).hex();
const lightError = errorColor.set("hsl.l", 0.45).set("hsl.s", 0.5).hex();
const lightWarning = warningColor.set("hsl.l", 0.45).set("hsl.s", 0.5).hex();
const lightInfo = infoColor.set("hsl.l", 0.45).set("hsl.s", 0.5).hex();

const lightTheme: ThemeOptions = {
  ...baseTheme,
  palette: {
    mode: "light",
    primary: { main: lightPrimary },
    secondary: { main: lightSecondary },
    success: { main: lightSuccess, contrastText: "#ffffff" },
    error: { main: lightError, contrastText: "#ffffff" },
    warning: { main: lightWarning, contrastText: "#ffffff" },
    info: { main: lightInfo, contrastText: "#ffffff" },
    complementary: {
      positives: [
        "#598b1b",
        "#5d9529",
        "#60a036",
        "#63aa43",
        "#65b54f",
        "#68bf5c",
        "#6aca68",
        "#6bd574",
      ],
      negatives: [
        "#c14047",
        "#c7544c",
        "#cd6650",
        "#d27755",
        "#d7885a",
        "#dc985f",
        "#e0a863",
        "#e4b868",
      ],
      neutrals: [
        "#411daf",
        "#543db7",
        "#6458bf",
        "#7173c7",
        "#7c8ecf",
        "#85a9d6",
        "#8dc4dd",
        "#93e0e4",
      ],
    },
    charts: [
      "#3f27ac",
      "#473bb4",
      "#4f4fbc",
      "#5763c4",
      "#6078cc",
      "#688cd4",
      "#70a0dc",
      "#78b4e4",
    ],
    background: {
      default: "#f8f8fd",
      paper: "#ffffff",
    },
    action: {
      disabled: alpha("#1B160E", 0.32),
      disabledBackground: alpha("#1B160E", 0.12),
    },
    text: {
      primary: alpha("#120f0a", 0.94),
      secondary: alpha("#120f0a", 0.7),
      disabled: alpha("#120f0a", 0.5),
      primaryWhite: "#f6f6f5",
      secondaryWhite: alpha("#f6f6f5", 0.7),
      primaryBlack: alpha("#120f0a", 0.94),
      secondaryBlack: alpha("#120f0a", 0.7),
    },
  },
};
// Tema Escuro
const darkPrimary = primaryColor.set("hsl.l", 0.6).set("hsl.s", 0.8).hex();
const darkSecondary = secondaryColor
  .set("hsl.l", 0.35)
  .set("hsl.s", 0.15)
  .hex();
const darkSecondaryDark = secondaryColor
  .set("hsl.l", 0.15)
  .set("hsl.s", 0.1)
  .hex();
const darkSuccess = successColor.set("hsl.l", 0.5).hex();
const darkError = errorColor.set("hsl.l", 0.5).hex();
const darkWarning = warningColor.set("hsl.l", 0.5).hex();
const darkInfo = infoColor.set("hsl.l", 0.5).hex();

const darkTheme: ThemeOptions = {
  ...baseTheme,
  palette: {
    mode: "dark",
    primary: { main: darkPrimary },
    secondary: { main: darkSecondary, dark: darkSecondaryDark },
    success: { main: darkSuccess, contrastText: "#ffffff" },
    error: { main: darkError, contrastText: "#ffffff" },
    warning: { main: darkWarning, contrastText: "#ffffff" },
    info: { main: darkInfo, contrastText: "#ffffff" },
    complementary: {
      positives: [
        "#598b1b",
        "#5d9529",
        "#60a036",
        "#63aa43",
        "#65b54f",
        "#68bf5c",
        "#6aca68",
        "#6bd574",
      ],
      negatives: [
        "#c14047",
        "#c7544c",
        "#cd6650",
        "#d27755",
        "#d7885a",
        "#dc985f",
        "#e0a863",
        "#e4b868",
      ],
      neutrals: [
        "#411daf",
        "#543db7",
        "#6458bf",
        "#7173c7",
        "#7c8ecf",
        "#85a9d6",
        "#8dc4dd",
        "#93e0e4",
      ],
    },
    charts: [
      "#3f27ac",
      "#473bb4",
      "#4f4fbc",
      "#5763c4",
      "#6078cc",
      "#688cd4",
      "#70a0dc",
      "#78b4e4",
    ],
    background: {
      default: secondaryColor.set("hsl.l", 0.07).hex(),
      paper: secondaryColor.set("hsl.l", 0.07).hex(),
    },
    action: {
      disabled: chroma("#120f0a").set("hsl.l", 0.8).alpha(0.3).hex(),
      disabledBackground: chroma("#120f0a").set("hsl.l", 0.8).alpha(0.12).hex(),
    },
    text: {
      primary: alpha("#f6f6f5", 0.94),
      secondary: alpha("#f6f6f5", 0.7),
      disabled: alpha("#f6f6f5", 0.5),
      primaryWhite: "#f6f6f5",
      secondaryWhite: alpha("#f6f6f5", 0.7),
      primaryBlack: alpha("#120f0a", 0.94),
      secondaryBlack: alpha("#120f0a", 0.7),
    },
  },
};

export {
  lightTheme,
  darkTheme,
  primaryColor,
  secondaryColor,
  successColor,
  errorColor,
  warningColor,
  infoColor,
};

import React, { PropsWithChildren } from "react";
import { lightTheme, darkTheme } from "../theme";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import ThemeModeProvider, {
  ThemeModeContext,
} from "../../providers/themeModeContext";

export default function TopLayout({ children }: PropsWithChildren) {
  return (
    <ThemeModeProvider>
      <ThemeModeContext.Consumer>
        {(themeMode) => (
          <ThemeProvider
            theme={createTheme(themeMode === "light" ? lightTheme : darkTheme)}
          >
            <CssBaseline />
            {children}
          </ThemeProvider>
        )}
      </ThemeModeContext.Consumer>
    </ThemeModeProvider>
  );
}
